<template>
  <backoffice-container>
    <b-card>
      <h5>{{ $t("backoffice.owner.select-type") }}:</h5>
      <!-- Owner type -->
      <div class="d-flex justify-content-around mt-1 flex-wrap">
        <div
          class="owner-background d-block mx-auto mt-50"
          :class="{ 'owner-background--active': isOrganization }"
          @click="getTypeOwner('organization')"
        >
          <feather-icon
            icon="BriefcaseIcon"
            size="30"
            class="d-block mx-auto"
            :class="isOrganization ? 'text-primary' : 'text-secondary'"
          />
          <h5
            class="mt-50"
            :class="isOrganization ? 'text-primary' : 'text-secondary'"
          >
            {{ $t("backoffice.owner.organization") }}
          </h5>
        </div>
        <div
          class="owner-background d-block mx-auto mt-50"
          :class="{ 'owner-background--active': isPerson }"
          @click="getTypeOwner('person')"
        >
          <feather-icon
            icon="UserIcon"
            size="30"
            class="d-block mx-auto"
            :class="isPerson ? 'text-primary' : 'text-secondary'"
          />
          <h5
            class="mt-50"
            :class="isPerson ? 'text-primary' : 'text-secondary'"
          >
            {{ $t("backoffice.owner.physical-person") }}
          </h5>
        </div>
      </div>
      <!-- Organization Content -->
      <b-collapse v-model="isOrganization" class="my-2" appear>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <div class="text-right my-2">
              <b-button
                variant="primary"
                class="mx-auto m-lg-0"
                type="submit"
                :disabled="isDisabled"
              >
                {{ $t("form.actions.save") }}
              </b-button>
            </div>
            <b-row>
              <b-col lg="6">
                <!-- Tradename -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="required"
                >
                  <b-form-group>
                    <label for="tradename" class="font-weight-bold">
                      {{ $t("backoffice.owner.tradename") }}*</label
                    >
                    <b-form-input
                      id="tradename"
                      v-model="form.name"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Legal name -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="required"
                >
                  <b-form-group>
                    <label for="legal-name" class="font-weight-bold">
                      {{ $t("backoffice.owner.legal-name") }}*</label
                    >
                    <b-form-input
                      id="legal-name"
                      v-model="form.legalName"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Alternative name -->
                <validation-provider #default="validationContext" name=" ">
                  <b-form-group>
                    <label for="alternative-name" class="font-weight-bold">
                      {{ $t("backoffice.owner.alternative-name") }}</label
                    >
                    <b-form-input
                      id="alternative-name"
                      v-model="form.alternativeName"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Nif -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="nif|required"
                >
                  <b-form-group>
                    <label for="nif" class="font-weight-bold">
                      {{ $t("backoffice.owner.nif") }}*</label
                    >
                    <b-form-input
                      id="nif"
                      v-model="form.nif"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Address -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="required"
                >
                  <b-form-group>
                    <label for="nif" class="font-weight-bold">
                      {{ $t("backoffice.owner.addess") }}*</label
                    >
                    <b-form-input
                      id="nif"
                      v-model="form.address"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="6">
                <!-- Phone -->
                <span class="d-flex">
                  <span>
                    <b-form-group>
                      <label for="phone-prefix" class="font-weight-bold">
                        {{ $t("backoffice.settings.prefix-label") }}</label
                      >
                      <v-select
                        id="phone-prefix"
                        v-model="form.country"
                        :placeholder="$t('form.select-placeholder')"
                        :options="prefixOptions"
                        :clearable="false"
                        :get-option-label="(a) => a.name[locale]"
                        label="name"
                        style="width: auto; min-width: 220px; margin-right: 5px"
                      />
                    </b-form-group>
                  </span>
                  <span class="flex-grow-1">
                    <validation-provider
                      #default="validationContext"
                      name=" "
                      rules="phone"
                    >
                      <b-form-group>
                        <label for="phone" class="font-weight-bold">
                          {{ $t("backoffice.owner.phone") }}
                        </label>
                        <b-form-input
                          id="phone"
                          v-model="form.phone"
                          type="text"
                          :placeholder="$t('form.type-placeholder')"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback class="text-danger">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </span>
                </span>
                <!-- Email -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="email|required"
                >
                  <b-form-group>
                    <label for="email" class="font-weight-bold">
                      {{ $t("backoffice.owner.email") }}*</label
                    >
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      type="email"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Legal Email -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="email"
                >
                  <b-form-group>
                    <label for="legal-email" class="font-weight-bold">
                      {{ $t("backoffice.owner.legal-email") }}</label
                    >
                    <b-form-input
                      id="legal-email"
                      v-model="form.legalEmail"
                      type="email"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Website -->
                <validation-provider
                  #default="validationContext"
                  name=""
                  rules="url"
                >
                  <b-form-group>
                    <label for="web" class="font-weight-bold">
                      {{ $t("backoffice.owner.web") }}</label
                    >
                    <b-form-input
                      id="web"
                      v-model="form.web"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-collapse>
      <!-- Person content -->
      <b-collapse v-model="isPerson" class="my-2" appear>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <div class="text-right my-2">
              <b-button
                variant="primary"
                class="mx-auto m-lg-0"
                type="submit"
                :disabled="isPersonDisabled"
              >
                {{ $t("form.actions.save") }}
              </b-button>
            </div>
            <b-row>
              <b-col lg="6">
                <!-- Name -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="required"
                >
                  <b-form-group>
                    <label for="tradename" class="font-weight-bold">
                      {{ $t("backoffice.owner.name") }}*</label
                    >
                    <b-form-input
                      id="tradename"
                      v-model="personForm.name"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Surname -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="required"
                >
                  <b-form-group>
                    <label for="surname" class="font-weight-bold">
                      {{ $t("backoffice.owner.surname") }}*</label
                    >
                    <b-form-input
                      id="surname"
                      v-model="personForm.surname"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Dni -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="dni|required"
                >
                  <b-form-group>
                    <label for="dni" class="font-weight-bold">
                      {{ $t("backoffice.owner.dni") }}*</label
                    >
                    <b-form-input
                      id="dni"
                      v-model="personForm.dni"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Address -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="required"
                >
                  <b-form-group>
                    <label for="nif" class="font-weight-bold">
                      {{ $t("backoffice.owner.addess") }}*</label
                    >
                    <b-form-input
                      id="nif"
                      v-model="personForm.address"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="6">
                <!-- Phone -->
                <span class="d-flex">
                  <span>
                    <b-form-group>
                      <label for="phone-prefix" class="font-weight-bold">
                        {{ $t("backoffice.settings.prefix-label") }}</label
                      >
                      <v-select
                        id="phone-prefix"
                        v-model="personForm.country"
                        :placeholder="$t('form.select-placeholder')"
                        :options="prefixOptions"
                        :clearable="false"
                        :get-option-label="(a) => a.name[locale]"
                        label="name"
                        style="width: auto; min-width: 220px; margin-right: 5px"
                      />
                    </b-form-group>
                  </span>
                  <span class="flex-grow-1">
                    <validation-provider
                      #default="validationContext"
                      name=" "
                      rules="phone"
                    >
                      <b-form-group>
                        <label for="phone" class="font-weight-bold">
                          {{ $t("backoffice.owner.phone") }}
                        </label>
                        <b-form-input
                          id="phone"
                          v-model="personForm.phone"
                          type="text"
                          :placeholder="$t('form.type-placeholder')"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback class="text-danger">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </span>
                </span>
                <!-- Email -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="email|required"
                >
                  <b-form-group>
                    <label for="email" class="font-weight-bold">
                      {{ $t("backoffice.owner.email") }}*</label
                    >
                    <b-form-input
                      id="email"
                      v-model="personForm.email"
                      type="email"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Website -->
                <validation-provider
                  #default="validationContext"
                  name=""
                  rules="url"
                >
                  <b-form-group>
                    <label for="web" class="font-weight-bold">
                      {{ $t("backoffice.owner.web") }}</label
                    >
                    <b-form-input
                      id="web"
                      v-model="personForm.web"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-collapse>
    </b-card>
  </backoffice-container>
</template>

<script>
import vSelect from "vue-select";

import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email, url, phone, nif, dni } from "@validations";
// import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";

export default {
  name: "Owner",
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        name: "",
        legalName: "",
        alternativeName: "",
        phone: null,
        country: null,
        web: null,
        nif: null,
        address: null,
        email: null,
        legalEmail: null,
      },
      personForm: {
        name: "",
        surname: "",
        phone: null,
        country: null,
        dni: null,
        address: null,
        email: null,
        web: null,
      },
      required,
      email,
      url,
      phone,
      nif,
      dni,
      isPerson: false,
      isOrganization: true,
      prefixOptions: [],
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    owner() {
      return this.$store.getters.owner;
    },
    isDisabled() {
      return (
        !this.form.name ||
        !this.form.legalName ||
        !this.form.nif ||
        !this.form.address ||
        !this.form.email
      );
    },
    isPersonDisabled() {
      return (
        !this.personForm.name ||
        !this.personForm.surname ||
        !this.personForm.dni ||
        !this.personForm.address ||
        !this.personForm.email
      );
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async created() {
    this.getPrefixes();
    await this.getOwnerData();
    if (this.owner?.dni) {
      this.personForm = this.owner;
      this.isOrganization = false;
      this.isPerson = true;
    } else {
      this.form = this.owner;
      this.isOrganization = true;
      this.isPerson = false;
    }
  },
  methods: {
    async onSubmit() {
      if (this.form.nif && this.personForm.dni) {
        return this.notifyError(this.$t("backoffice.owner.messages.error"));
      }
      this.notifySuccess(this.$t("backoffice.owner.messages.success"));
      this.$store.dispatch("addCommunityCustomizations", {
        owner: this.isOrganization ? this.form : this.personForm,
      });
    },

    getTypeOwner(item) {
      if (item === "organization") {
        this.isOrganization = true;
        this.isPerson = false;
      } else {
        this.isOrganization = false;
        this.isPerson = true;
      }
    },
    async getOwnerData() {
      await this.$store.dispatch("getCommunityCustomizationsOwner");
    },
    async getPrefixes() {
      const response = await this.$store.dispatch("getPrefixes");
      this.prefixOptions = response.data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.owner-background {
  cursor: pointer;
  &--active {
    color: $primary;
    cursor: pointer;
    padding-bottom: 10px;
    border-bottom: solid $primary 2px;
  }
}
</style>
